import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { AppLayout } from '@solid/constants/layout.constant';
import { AppLayoutService } from '@solid/services/app-layout.service';

export const setAppLayout = (layout: AppLayout): ResolveFn<void> => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    inject(AppLayoutService).layout.set(layout);
  };
};
