import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from '@sentry/angular';

Sentry.init({
  dsn: 'https://7e489bcf068864d1d0d2f139f455f847@o4507979381538816.ingest.de.sentry.io/4508007372947536',
  integrations: [],
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
