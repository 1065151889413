import { Component, inject, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { EmojiComponent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TrelloService } from '@solid/services/integrations/trello.service';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { RichTextEditorComponent } from '../../shared/components/rich-text-editor/rich-text-editor.component';
import { SolidButtonDirective } from '../../shared/directives/button/button.directive';
import WindowUtils from '../../utils/window.utils';

@Component({
  selector: 'app-development',
  standalone: true,
  imports: [
    RichTextEditorComponent,
    ReactiveFormsModule,
    FormsModule,
    EmojiComponent,
    PickerComponent,
    SolidButtonDirective,
    NzSelectModule,
  ],
  templateUrl: './development.component.html',
  host: {
    class: 'flex flex-col w-full h-full bg-gray-200 px-8 py-4 gap-8',
  },
})
export class DevelopmentComponent {
  //#region Injections
  private trelloService = inject(TrelloService);
  //#endregion

  //#region Data Variables
  trelloBoards = signal<any[]>([]);
  //#endregion

  constructor() {}

  hndAddEmoji(event: any) {
    console.log(event);
  }

  hndLoginWithTrello() {
    this.trelloService.getAuthorizationUrl().subscribe({
      next: (response) => {
        const WINDOW_WIDTH = 600;
        const WINDOW_HEIGHT = 600;

        const position = WindowUtils.getCenter({
          width: WINDOW_WIDTH,
          height: WINDOW_HEIGHT,
        });

        WindowUtils.open(response, {
          popup: false,
          width: 600,
          height: 600,
          ...position,
        }).subscribe({
          next: () => {
            const canContinue = this.trelloService.isLoggedIn();

            if (!canContinue) {
              throw new Error('An error occurred. Please try again.');
            }

            this.trelloService.getBoards().subscribe({
              next: (boards) => {
                this.trelloBoards.set(boards);
              },
            });
          },
        });
      },
    });
  }

  public throwTestError(): void {
    throw new Error('Sentry Test Error');
  }
}
