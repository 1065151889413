import { Routes } from '@angular/router';
import { AppLayout } from '@solid/constants/layout.constant';
import { HasPermissions } from './core/auth/guards/has-permissions.guard';
import { PreventIfLoggedInGuard } from './core/auth/guards/prevent-if-logged-in-guard.guard';
import { PreventIfNotLoggedInGuard } from './core/auth/guards/prevent-if-not-logged-in-guard.guard';
import { DevelopmentComponent } from './core/features/development/development.component';
import { setAppLayout } from './core/resolvers/app-layout.resolver';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./core/auth/auth.routes').then((m) => m.routes),
    canActivate: [PreventIfLoggedInGuard],
    resolve: {
      layout: setAppLayout(AppLayout.Unauthorized),
    },
  },
  {
    path: 'integrations',
    canActivate: [PreventIfNotLoggedInGuard],
    loadChildren: () =>
      import('./core/features/integrations/integrations.routes').then(
        (m) => m.routes,
      ),
  },
  {
    path: '',
    resolve: {
      layout: setAppLayout(AppLayout.Authorized),
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        canActivate: [PreventIfNotLoggedInGuard],
        loadChildren: () =>
          import('./core/features/dashboard/dashboard.routes').then(
            (m) => m.routes,
          ),
      },
      {
        path: 'projects',
        canActivate: [PreventIfNotLoggedInGuard],
        loadChildren: () =>
          import('./core/features/project/project.routes').then(
            (m) => m.routes,
          ),
      },
      {
        path: 'roadmap',
        canActivate: [PreventIfNotLoggedInGuard],
        loadChildren: () =>
          import('./core/features/roadmap/roadmap.routes').then(
            (m) => m.routes,
          ),
      },
      {
        path: 'dev',
        component: DevelopmentComponent,
        canActivate: [PreventIfNotLoggedInGuard],
      },
      {
        path: 'contacts',
        canActivate: [PreventIfNotLoggedInGuard, HasPermissions],
        loadChildren: () =>
          import('./core/features/contacts/contacts.routes').then(
            (m) => m.routes,
          ),
        data: {
          permissions: {
            only: ['owner', 'admin'],
            redirectTo: {
              navigationCommands: ['/', 'dashboard'],
            },
          },
        },
      },
      {
        path: 'settings',
        canActivate: [PreventIfNotLoggedInGuard, HasPermissions],
        loadChildren: () =>
          import('./core/features/settings/settings.routes').then(
            (m) => m.routes,
          ),
        data: {
          permissions: {
            only: ['owner', 'admin'],
            redirectTo: {
              navigationCommands: ['/', 'dashboard'],
            },
          },
        },
      },
      {
        path: ':companyId',
        canActivate: [PreventIfNotLoggedInGuard],
        // TODO: add company guard to check companyId exists and if user is part of company, if not redirect to dashboard or 404
        loadChildren: () =>
          import('./core/features/company/company.routes').then(
            (m) => m.routes,
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
