import { createAction, props } from '@ngrx/store';
import { WorkspaceProject } from '@solid/types/project.types';

export const ProjectActions = {
  get: createAction('[Project] Get project', props<{ projectId: number }>()),
  getSuccess: createAction(
    '[Project] Get project success',
    props<{
      project: WorkspaceProject;
    }>(),
  ),
  getFailure: createAction(
    '[Project] Get project failure',
    props<{
      error: string;
    }>(),
  ),
  getAll: createAction('[Project] Get all projects'),
  getAllSuccess: createAction(
    '[Project] Get all projects success',
    props<{
      projects: WorkspaceProject[];
    }>(),
  ),
  getAllFailure: createAction(
    '[Project] Get all projects failure',
    props<{
      error: string;
    }>(),
  ),
  update: createAction(
    '[Project] Update project',
    props<{ project: WorkspaceProject }>(),
  ),
  remove: createAction(
    '[Project] Remove project',
    props<{ projectId: number }>(),
  ),
  refresh: createAction(
    '[Project] Refresh project',
    props<{ projectId: number }>(),
  ),
};
