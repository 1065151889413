import { Injectable, signal } from '@angular/core';
import { version } from '../../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  //#region Signals
  version = signal(version).asReadonly();
  //#endregion

  constructor() {}
}
