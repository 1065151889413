import { createReducer, on } from '@ngrx/store';
import { WorkspaceProject } from '@solid/types/project.types';
import { ProjectActions } from '../actions/project.actions';

export interface IProjectState {
  loading: {
    project: boolean;
    projects: boolean;
  };
  project: WorkspaceProject | undefined;
  projects: WorkspaceProject[] | undefined;
  error: string;
}

export const initialState: IProjectState = {
  loading: {
    project: false,
    projects: false,
  },
  project: undefined,
  projects: undefined,
  error: '',
};

export const projectReducer = createReducer(
  initialState,
  on(ProjectActions.get, (state) => ({
    ...state,
    loading: {
      ...state.loading,
      project: true,
    },
  })),
  on(ProjectActions.getSuccess, (state, { project }) => ({
    ...state,
    loading: {
      ...state.loading,
      project: false,
    },
    project,
  })),
  on(ProjectActions.getFailure, (state, { error }) => ({
    ...state,
    loading: {
      ...state.loading,
      project: false,
    },
    error,
  })),
);
