import { createReducer, on } from '@ngrx/store';
import { login, loginFailure, loginSuccess } from '../actions/auth.actions';
import { Session } from '@supabase/supabase-js';

export interface IAuthState {
  loading: boolean;
  error: string;
  session: Session | undefined;
}

export const initialState: IAuthState = {
  loading: false,
  error: '',
  session: undefined,
};

export const authReducer = createReducer(
  initialState,
  on(login, (state) => ({
    ...state,
    loading: true,
  })),
  on(loginSuccess, (state, { session }) => ({
    ...state,
    loading: false,
    session,
  })),
  on(loginFailure, (state, { error }) => ({
    ...state,
    error,
  })),
);
