import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, Router } from '@angular/router';

import { registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { NzContextMenuService } from 'ng-zorro-antd/dropdown';
import { en_GB, provideNzI18n } from 'ng-zorro-antd/i18n';
import {
  provideCacheableAnimationLoader,
  provideLottieOptions,
} from 'ngx-lottie';
import { NgxPermissionsModule } from 'ngx-permissions';
import { QuillModule } from 'ngx-quill';
import { routes } from './app.routes';
import { quillEditorConfig } from './core/configs/quill-modules.config';
import * as projectEffects from './core/ngrx/effects/project.effects';
import { authReducer } from './core/ngrx/reducers/auth.reducer';
import { projectReducer } from './core/ngrx/reducers/project.reducer';

registerLocaleData(en);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideNzI18n(en_GB),
    importProvidersFrom(
      FormsModule,
      ReactiveFormsModule,
      QuillModule.forRoot(quillEditorConfig),
      NgxPermissionsModule.forRoot(),
    ),
    provideAnimationsAsync(),
    provideHttpClient(),
    provideAngularSvgIcon(),
    NzContextMenuService,
    provideStore({
      auth: authReducer,
      project: projectReducer,
    }),
    provideEffects(projectEffects),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),

    // add dynamic lottie-web import
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideCacheableAnimationLoader(),
    // {
    //   provide: TOLGEE_INSTANCE,
    //   useFactory: () => {
    //     return Tolgee()
    //       .use(FormatSimple())
    //       .use(BackendFetch())
    //       .use(DevTools())
    //       .init({
    //         apiUrl: environment.tolgeeApiUrl,
    //         apiKey: environment.tolgeeApiKey,
    //         fallbackLanguage: 'en',
    //         defaultLanguage: 'en',
    //         availableLanguages: ['en', 'es', 'ro'],
    //         projectId: 4,
    //         ns: ['test'],
    //       });
    //   },
    // },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
