import { Injectable, inject } from '@angular/core';
import { ServiceBase } from './service-base';
import { AuthService } from '../auth/auth.service';
import { map, take } from 'rxjs';
import { LAST_ONBOARD_STEP } from '@solid/constants/onboard.constant';
import { Dialog } from '@angular/cdk/dialog';
import { IOnboardData } from '../shared/components/onboard/onboard.component';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardService extends ServiceBase {
  //#region Constants
  private readonly authService = inject(AuthService);
  private readonly dialogService = inject(Dialog);
  private readonly projectService = inject(ProjectService);
  //#endregion

  //#region Data Variables
  hasFinishedOnboarding$ = this.authService.profile$.pipe(
    map((profile) => {
      if (!profile) return true;

      return profile.onboarding_step > LAST_ONBOARD_STEP;
    }),
  );
  //#endregion

  constructor() {
    super();

    this.hasFinishedOnboarding$.pipe(take(1)).subscribe({
      next: (hasFinished) => {
        if (!hasFinished) {
          this.showOnboarding();
        }
      },
    });
  }

  //#region Private Methods
  private async showOnboarding() {
    const { OnboardComponent } = await import(
      '../shared/components/onboard/onboard.component'
    );

    this.authService.profile$.pipe(take(1)).subscribe({
      next: (profile) => {
        if (!profile) return;

        const data: IOnboardData = {
          profile,
          step: profile.onboarding_step,
        };

        const dialog = this.dialogService.open(OnboardComponent, {
          width: '100%',
          height: '100%',
          closeOnNavigation: false,
          disableClose: true,
          data,
        });

        dialog.closed.subscribe({
          next: () => {
            this.projectService.project$.pipe(take(1)).subscribe({
              next: (project) => {
                if (!project) {
                  return;
                }

                this.projectService.loadProject(project.id);
              },
            });
          },
        });
      },
    });
  }
  //#endregion
}
