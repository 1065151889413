import { inject, Injectable, signal } from '@angular/core';
import { WorkspaceService } from './workspace.service';
import {
  combineLatest,
  debounce,
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  of,
  skipWhile,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  //#region Injections
  private readonly authService = inject(AuthService);
  private readonly workspaceService = inject(WorkspaceService);
  //#endregion

  //#region Control Variables
  loading = toSignal(
    combineLatest([
      this.authService.profile$,
      this.workspaceService.onWorkspaceLoading$,
    ]).pipe(
      distinctUntilChanged(
        (previous, current) =>
          JSON.stringify(previous) === JSON.stringify(current),
      ),
      switchMap(([profile, loadingWorkspace]) => {
        if (loadingWorkspace) {
          return of(true);
        }

        return of(false).pipe(delay(Math.random() * 1000));
      }),
      debounce((value) => {
        return value ? of(true) : timer(250);
      }),
    ),
  );
  //#endregion

  constructor() {}
}
