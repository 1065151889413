import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProjectService } from '../../services/project.service';
import { ProjectActions } from '../actions/project.actions';
import { catchError, exhaustMap, map, switchMap } from 'rxjs';

export const loadProjectEffect = createEffect(
  (actions$ = inject(Actions), projectService = inject(ProjectService)) => {
    return actions$.pipe(
      ofType(ProjectActions.get),
      exhaustMap((action) =>
        projectService.getProjectObservable(action.projectId).pipe(
          map((response) => {
            if (response.error || !response.data) {
              return ProjectActions.getFailure({
                error: response.error?.message || 'Unknown error',
              });
            }

            return ProjectActions.getSuccess({ project: response.data });
          }),
        ),
      ),
    );
  },
  {
    functional: true,
  },
);
