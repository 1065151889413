import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { AppLayout } from '@solid/constants/layout.constant';
import { AppLayoutService } from '@solid/services/app-layout.service';
import { BillingService } from '@solid/services/billing.service';
import { UserInvitationsService } from '@solid/services/invitation.service';
import { OnboardService } from '@solid/services/onboard.service';
import { debounceTime, fromEvent } from 'rxjs';
import { AuthService } from './core/auth/auth.service';
import { AppService } from './core/services/app.service';
import { AuthorizedLayoutComponent } from './core/shared/components/layout/authorized-layout/authorized-layout.component';
import { BlankLayoutComponent } from './core/shared/components/layout/blank-layout/blank-layout.component';
import { ErrorLayoutComponent } from './core/shared/components/layout/error-layout/error-layout.component';
import { UnauthorizedLayoutComponent } from './core/shared/components/layout/unauthorized-layout/unauthorized-layout.component';
import { LoadingComponent } from './core/shared/components/loading/loading.component';
import { LoadingService } from '@solid/services/loading.service';
import {
  fadeInOutAnimation,
  fadeOutAnimation,
} from './core/shared/animations/fade-animations';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    AsyncPipe,

    // components
    LoadingComponent,

    // project
    AuthorizedLayoutComponent,
    UnauthorizedLayoutComponent,
    BlankLayoutComponent,
    ErrorLayoutComponent,
  ],
  templateUrl: './app.component.html',
  host: {
    class:
      'grid grid-cols-[max-content_1fr_max-content] grid-rows-[max-content_1fr] min-w-full min-h-dvh overflow-auto max-h-full font-sans',
  },
  providers: [],
  animations: [fadeOutAnimation],
})
export class AppComponent {
  //#region Injections
  // ? some of the unused services are here just for initialization, do not remove it
  private appService = inject(AppService);
  private appLayoutService = inject(AppLayoutService);
  private authService = inject(AuthService);
  private onboardService = inject(OnboardService);
  private invitationService = inject(UserInvitationsService);
  private billingService = inject(BillingService);
  private loadingService = inject(LoadingService);
  //#endregion

  //#region Observables
  private resize$ = fromEvent(window, 'resize');
  //#endregion

  //#region Signals
  user = toSignal(this.authService.user$);
  layout = this.appLayoutService.layout;
  version = this.appService.version;
  hasCompletedOnboarding = toSignal(this.onboardService.hasFinishedOnboarding$);

  loading = this.loadingService.loading;
  //#endregion

  //region Constants
  readonly AppLayout = AppLayout;
  //#endregion

  constructor() {
    this.resize$.pipe(takeUntilDestroyed(), debounceTime(500)).subscribe({
      next: (event) => {
        const target = event.target as Window;
        const log = {
          width: target.innerWidth,
          height: target.innerHeight,
          breakpoint: '',
        };

        if (target.innerWidth <= 640) {
          log.breakpoint = 'sm';
        } else if (target.innerWidth <= 768) {
          log.breakpoint = 'md';
        } else if (target.innerWidth <= 1024) {
          log.breakpoint = 'lg';
        } else if (target.innerWidth <= 1280) {
          log.breakpoint = 'xl';
        } else {
          log.breakpoint = '2xl';
        }

        console.debug(log);
      },
    });
  }
}
